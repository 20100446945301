import React from 'react';
import { Helmet } from 'react-helmet';

import S01 from './S01';
import S02 from './S02';
import S03 from './S03';
import S04 from './S04';
import S05 from './S05';
import S06 from './S06';

const Page = () => {
  return (
    <div>
      <Helmet>
        <title>보다 | 드론의 새로운 라이프 스타일</title>
        <meta name="description" content="드론시장의 문제점을 기회로 바꾸고자 하는 스타트업입니다." />
      </Helmet>
      <S01 />
      <S02 />
      <S03 />
      <S04 />
      <S05 />
      <S06 />
    </div>
  );
};

export default Page;
