import React from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import styles from './S04.module.scss';

const cx = classNames.bind(styles);

const Section = () => {
  const { t, i18n } = useTranslation();

  const openToJoin = () => {
    window.open('https://www.nalida.io/join');
  };

  return (
    <div className={cx('container')}>
      <div className={cx('wrapper')}>
        <h1>{t('날리다 서비스를 신청해보세요')}</h1>
        <img
          src={
            i18n.language === 'ko'
              ? require(`../../assets/images/nalida/S04/diagram-ko.png`)
              : require(`../../assets/images/nalida/S04/diagram-en.png`)
          }
          alt="NALIDA"
          className={cx(['diagram', 'desktop'])}
        />
        <img
          src={
            i18n.language === 'ko'
              ? require(`../../assets/images/nalida/S04/diagram-mo-ko.png`)
              : require(`../../assets/images/nalida/S04/diagram-mo-en.png`)
          }
          alt="NALIDA"
          className={cx(['diagram', 'mobile'])}
        />
        <ul>
          <li>
            <div className={cx('title')}>{t('날리고 싶어요')}</div>
            <img src={require('../../assets/images/nalida/S04/illust-01.png')} alt={t('날리고 싶어요')} />
            <div className={cx('button')} onClick={openToJoin}>
              <div className={cx('desc')}>{t('드론 일거리를 찾고 계신가요?')}</div>
              Find Jobs
            </div>
          </li>
          <li>
            <div className={cx(['title', 'navy'])}>{t('데이터가 필요해요')}</div>
            <img src={require('../../assets/images/nalida/S04/illust-02.png')} alt={t('데이터가 필요해요')} />
            <div className={cx(['button', 'navy'])} onClick={openToJoin}>
              <div className={cx('desc')}>{t('드론 데이터가 필요하신가요?')}</div>
              Order Data
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Section;
