import React from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import styles from './S02.module.scss';

const cx = classNames.bind(styles);

const Section = () => {
  const { t } = useTranslation();

  return (
    <div className={cx('container')}>
      <h1>{t('신뢰받는 드론 서비스')}</h1>
      <p>{t('드론이 사용되는 다양한 분야에서 수익을 창출해보세요.')}</p>
      <ul>
        <li>
          <img
            src={require('../../assets/images/nalida/S02/illust-01.png')}
            alt={t('영상촬영')}
          />
          <div className={cx('title')}>{t('영상촬영')}</div>
          <div className={cx('subtitle')}>{t('드라마, 예능')}</div>
        </li>
        <li>
          <img
            src={require('../../assets/images/nalida/S02/illust-02.png')}
            alt={t('농업방제')}
          />
          <div className={cx('title')}>{t('농업방제')}</div>
          <div className={cx('subtitle')}>{t('약제살포, 작물판독')}</div>
        </li>
        <li>
          <img
            src={require('../../assets/images/nalida/S02/illust-03.png')}
            alt={t('지적측량')}
          />
          <div className={cx('title')}>{t('지적측량')}</div>
          <div className={cx('subtitle')}>{t('공공측량, 시계열 분석')}</div>
        </li>
        <li>
          <img
            src={require('../../assets/images/nalida/S02/illust-04.png')}
            alt={t('부동산')}
          />
          <div className={cx('title')}>{t('부동산')}</div>
          <div className={cx('subtitle')}>{t('지도정보, 가격, 면적')}</div>
        </li>
        <li>
          <img
            src={require('../../assets/images/nalida/S02/illust-05.png')}
            alt={t('시설물점검')}
          />
          <div className={cx('title')}>{t('시설물점검')}</div>
          <div className={cx('subtitle')}>
            {t('댐/저수지 균열, 열배관 누수탐지, 도로균열 분석')}
          </div>
        </li>
        <li>
          <img
            src={require('../../assets/images/nalida/S02/illust-06.png')}
            alt={t('에너지')}
          />
          <div className={cx('title')}>{t('에너지')}</div>
          <div className={cx('subtitle')}>
            {t('안전점검, 태양광 패널 점검')}
          </div>
        </li>
        <li>
          <img
            src={require('../../assets/images/nalida/S02/illust-07.png')}
            alt={t('수색정찰')}
          />
          <div className={cx('title')}>{t('수색정찰')}</div>
          <div className={cx('subtitle')}>{t('모니터링, 전술지휘')}</div>
        </li>
        <li>
          <img
            src={require('../../assets/images/nalida/S02/illust-08.png')}
            alt={t('기체제작')}
          />
          <div className={cx('title')}>{t('기체제작')}</div>
          <div className={cx('subtitle')}>{t('나만의 드론 제작')}</div>
        </li>
      </ul>
    </div>
  );
};

export default Section;
