import React from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import styles from './Footer.module.scss';

const cx = classNames.bind(styles);

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className={cx('container')}>
      <div className={cx('top')}>
        <img
          src={require('../assets/images/logo.png')}
          alt="BODA"
          draggable={false}
        />
      </div>
      <div className={cx('line')} />
      <div className={cx('bottom')}>
        <div className={cx('left')}>
          <div className={cx('item')}>
            <div className={cx('title')}>Company</div>
            <div className={cx('content')}>BODA Inc.</div>
          </div>
          <div className={cx('item')}>
            <div className={cx('title')}>CEO</div>
            <div className={cx('content')}>Tom Kim</div>
          </div>
          <div className={cx('item')}>
            <div className={cx('title')}>Office</div>
            <div className={cx('content')}>
              {t('광주 서구 상무중앙로 78번길 5-6')} {t('영효빌딩 9층')}
            </div>
          </div>
          <div className={cx('item')}>
            <div className={cx('title')}>Fax</div>
            <div className={cx('content')}>050 4054 8835</div>
          </div>
          <div className={cx('item')}>
            <div className={cx('title')}>Email</div>
            <div className={cx('content')}>
              <a
                href="mailto:admin@droneboda.com"
                target="_blank"
                rel="noreferrer"
              >
                admin@droneboda.com
              </a>
            </div>
          </div>
        </div>
        <div className={cx('right')}>
          <div className={cx('copyright')}>
            Copyright (주)보다 ALL RIGHTS RESERVED.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
