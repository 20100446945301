import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { IoChevronDownOutline, IoChevronUpOutline } from 'react-icons/io5';

import styles from './S03.module.scss';

const cx = classNames.bind(styles);

const Section = () => {
  const { t } = useTranslation();
  const [openIndex, setOpenIndex] = useState(null);

  const toggle = (index) => {
    if (openIndex === index) {
      setOpenIndex(null);
    } else {
      setOpenIndex(index);
    }
  };

  return (
    <div className={cx('container')}>
      <h1>{t('자주 묻는 질문')}</h1>
      <ul>
        <li>
          <div className={cx('header')} onClick={() => toggle(0)}>
            <div className={cx('mark')}>Q</div>
            <div className={cx('question')}>{t('날리다는 무슨 서비스 인가요?')}</div>
            {openIndex === 0 ? (
              <IoChevronUpOutline size={32} color="white" />
            ) : (
              <IoChevronDownOutline size={32} color="white" />
            )}
          </div>
          {openIndex === 0 && (
            <p className={cx('answer')}>
              {t('날리다 서비스는 드론 전문가들과 데이터 의뢰자들을 연결해주는 플랫폼입니다.')}
              <br />
              {t('드론을 전문가처럼 비행 할 수 있게 배워보세요. 드론을 통해 수익을 창출할 수 있습니다.')}
            </p>
          )}
        </li>
        <li>
          <div className={cx('header')} onClick={() => toggle(1)}>
            <div className={cx('mark')}>Q</div>
            <div className={cx('question')}>{t('어떻게 지원할 수 있나요?')}</div>
            {openIndex === 1 ? (
              <IoChevronUpOutline size={32} color="white" />
            ) : (
              <IoChevronDownOutline size={32} color="white" />
            )}
          </div>
          {openIndex === 1 && (
            <p className={cx('answer')}>
              {t('지원방법은 2가지가 있습니다.')}
              <br />
              {t('1. “Find Jobs” “Order Data“ 버튼을 클릭하여 날리다 웹사이트에서 작성할 수 있습니다.')}
              <br />
              {t('2. 하단에 이메일로 자세한 정보를 보내주시면 성실하게 답변 드리겠습니다.')}
            </p>
          )}
        </li>
        <li>
          <div className={cx('header')} onClick={() => toggle(2)}>
            <div className={cx('mark')}>Q</div>
            <div className={cx('question')}>{t('예산 범위/데이터 확보 등 어떻게 서비스를 이용할 수 있나요?')}</div>
            {openIndex === 2 ? (
              <IoChevronUpOutline size={32} color="white" />
            ) : (
              <IoChevronDownOutline size={32} color="white" />
            )}
          </div>
          {openIndex === 2 && (
            <p className={cx('answer')}>
              {t('1. 날리다 웹사이트를 통해 문의 내용을 넘겨주시면 답변을 전문가와 상의를 통해 보내 드립니다.')}
              <br />
              {t('2. 세부적인 내용을 통해 기체-인력-소프트웨어와 후처리 데이터까지 제언을 드립니다.')}
              <br />
              {t('3. 배정된 상당자를 통해 정확한 데이터 확보 시기와 워크플로우를 받을 수 있습니다.')}
              <br />
            </p>
          )}
        </li>
        <li>
          <div className={cx('header')} onClick={() => toggle(3)}>
            <div className={cx('mark')}>Q</div>
            <div className={cx('question')}>{t('어플리케이션(APP)으로 출시 예정인가요?')}</div>
            {openIndex === 3 ? (
              <IoChevronUpOutline size={32} color="white" />
            ) : (
              <IoChevronDownOutline size={32} color="white" />
            )}
          </div>
          {openIndex === 3 && (
            <p className={cx('answer')}>
              {t('보다는 날리다 서비스를 오프라인으로 진행중이고 온라인으로 시장을 확대하고자 합니다.')}
              <br />
              {t('정식으로 앱이 출시 될 수 있도록 수많은 노력을 하고 있습니다!')}
            </p>
          )}
        </li>
        <li>
          <div className={cx('header')} onClick={() => toggle(4)}>
            <div className={cx('mark')}>Q</div>
            <div className={cx('question')}>{t('날리다의 목표가 있나요?')}</div>
            {openIndex === 4 ? (
              <IoChevronUpOutline size={32} color="white" />
            ) : (
              <IoChevronDownOutline size={32} color="white" />
            )}
          </div>
          {openIndex === 4 && (
            <p className={cx('answer')}>
              {t(
                '날리다팀은 드론 서비스 시장에 대한 엄청난 열정으로 누구나 어디서든 쉽게 사용 할 수 있는 드론 마켓플레이스를 만드는데 목표를 두고 있습니다.'
              )}
              <br />
              {t('복잡하고 어려운 드론 데이터를 쉽고 편리한 데이터로 만다는것이 저희 팀의 목표입니다.')}
            </p>
          )}
        </li>
      </ul>
    </div>
  );
};

export default Section;
