import React from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import styles from './S01.module.scss';

const cx = classNames.bind(styles);

const Section = () => {
  const { t, i18n } = useTranslation();

  const openToJoin = () => {
    window.open('https://www.nalida.io/join');
  };

  return (
    <div className={cx('container')}>
      <div className={cx('wrapper')}>
        <img src={require('../../assets/images/nalida/S01/illust.png')} alt="NALIDA" />
        <div className={cx('top')}>
          <h1>{t('국내 유일 드론 매칭 플랫폼')}</h1>
          <img src={require('../../assets/images/nalida/S01/logo.png')} alt="NALIDA" />
          <p>
            {t('누구나 드론 전문가로 만들어드립니다.')}
            <br />
            {t('드론으로 전문가로 활동하고 수익을 창출해 보세요!')}
          </p>
          <div className={cx('buttons')}>
            <div className={cx('button')} onClick={openToJoin}>
              <div className={cx('desc')}>{t('드론 일거리를 찾고 계신가요?')}</div>
              Find Jobs
            </div>
            <div className={cx('button')} onClick={openToJoin}>
              <div className={cx('desc')}>{t('드론 데이터가 필요하신가요?')}</div>
              Order Data
            </div>
          </div>
        </div>
        <div className={cx('bottom')}>
          <h2>
            {t('인력양성과 드론 서비스를 위한')}&nbsp;
            <br />
            <span className={cx('accent')}>{t('온+오프라인 드론 매칭 서비스,')}</span>
            &nbsp;
            {t('날리다')}
          </h2>
          <p>{t('드론산업과 소비자를 연결하는 서비스를 제공합니다.')}</p>
          <img
            src={
              i18n.language === 'ko'
                ? require(`../../assets/images/nalida/S01/diagram-ko.png`)
                : require(`../../assets/images/nalida/S01/diagram-en.png`)
            }
            alt="NALIDA"
            className={cx(['diagram', 'desktop'])}
          />
          <img
            src={
              i18n.language === 'ko'
                ? require(`../../assets/images/nalida/S01/diagram-mo-ko.png`)
                : require(`../../assets/images/nalida/S01/diagram-mo-en.png`)
            }
            alt="NALIDA"
            className={cx(['diagram', 'mobile'])}
          />
        </div>
      </div>
    </div>
  );
};

export default Section;
