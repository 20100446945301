import React from 'react';
import { Helmet } from 'react-helmet';

import S01 from './S01';
import S02 from './S02';
import S03 from './S03';
import S04 from './S04';

const Page = () => {
  return (
    <div>
      <Helmet>
        <title>NALIDA</title>
        <meta name="description" content="드론 전문가로 활동하고 수익을 창출해보세요!" />
      </Helmet>
      <S01 />
      <S02 />
      <S03 />
      <S04 />
    </div>
  );
};

export default Page;
