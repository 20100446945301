import React, { useState } from 'react';
import classNames from 'classnames/bind';
import Typewriter from 'typewriter-effect';

import styles from './S01.module.scss';

const cx = classNames.bind(styles);

const VALUES = [
  {
    title: 'Drone Data.',
    description: 'Whatever you make, make it known with BODA.',
  },
  {
    title: 'Connection.',
    description: 'You’re going to need worldwide connection.',
  },
  {
    title: 'Education.',
    description: 'Education is the key to bring the next tomorrow.',
  },
];

const Section = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className={cx('container')}>
      <div className={cx('wrapper')}>
        <div className={cx('left')}>
          <h1>
            We Value
            <br />
            <span className={cx('topic')}>
              <Typewriter
                onInit={(typewriter) => {
                  typewriter
                    .typeString(VALUES[0].title)
                    .pauseFor(2000)
                    .deleteAll()
                    .callFunction(() => setActiveIndex(1))
                    .typeString(VALUES[1].title)
                    .pauseFor(2000)
                    .deleteAll()
                    .callFunction(() => setActiveIndex(2))
                    .typeString(VALUES[2].title)
                    .pauseFor(2000)
                    .deleteAll()
                    .callFunction(() => setActiveIndex(0))
                    .start();
                }}
                options={{ loop: true }}
              />
            </span>
          </h1>
          <p>{VALUES[activeIndex].description}</p>
        </div>
        <div className={cx('right')}>
          <div className={cx('image')}>
            <div
              className={cx(['overlay', 'i01', { active: activeIndex === 0 }])}
            />
            <div
              className={cx(['overlay', 'i02', { active: activeIndex === 1 }])}
            />
            <div
              className={cx(['overlay', 'i03', { active: activeIndex === 2 }])}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section;
