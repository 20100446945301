import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import styles from './S04.module.scss';

const cx = classNames.bind(styles);

const Section = () => {
  const { t } = useTranslation();
  const [index, setIndex] = useState(0);

  const handleScroll = (e) => {
    if (e.target.scrollLeft % document.body.clientWidth === 0) {
      setIndex(e.target.scrollLeft / document.body.clientWidth);
    }
  };

  return (
    <div className={cx('container')}>
      <div className={cx('wrapper')}>
        <h1>Problems</h1>
        <p>{t('어떤 문제점들이 있죠?')}</p>
      </div>
      <ul onScroll={handleScroll} className={cx('items')}>
        <li>
          <img
            src={require('../../assets/images/home/S04/problem-01.png')}
            alt={t('부족한 경험')}
          />
          <h2>01. {t('부족한 경험')}</h2>
          <p>
            {t('조종사들은 매년 증가하지만')}
            <br />
            {t('드론 활용 산업 경험이 부족합니다.')}
          </p>
        </li>
        <li>
          <img
            src={require('../../assets/images/home/S04/problem-02.png')}
            alt={t('사용 없는 드론')}
          />
          <h2>02. {t('사용 없는 드론')}</h2>
          <p>
            {t('세상에는 수 많은 드론들이 있지만')}
            <br />
            {t('날지 못하고 있는 드론이 많습니다.')}
          </p>
        </li>
        <li>
          <img
            src={require('../../assets/images/home/S04/problem-03.png')}
            alt={t('교육의 부재')}
          />
          <h2>03. {t('교육의 부재')}</h2>
          <p>
            {t('조종사들은 부족한 전문')}
            <br />
            {t('교육으로 인해 기회를 놓치고 있습니다.')}
          </p>
        </li>
      </ul>
      <ul className={cx('dots')}>
        <li className={cx({ active: index === 0 })} />
        <li className={cx({ active: index === 1 })} />
        <li className={cx({ active: index === 2 })} />
      </ul>
    </div>
  );
};

export default Section;
