import React, { useState, useRef } from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { IoCloseOutline, IoCheckmarkOutline } from 'react-icons/io5';
import axios from 'axios';

import styles from './T01.module.scss';

const cx = classNames.bind(styles);

const Section = ({ close }) => {
  const { t } = useTranslation();
  const [isProcessing, setProcessing] = useState(false);
  const [info, setInfo] = useState({
    name: '',
    email: '',
    company: '',
    position: '',
    message: '',
  });
  const [agree, setAgree] = useState(false);

  const nameRef = useRef();
  const emailRef = useRef();
  const messageRef = useRef();

  const handleChange = (e) => {
    setInfo({
      ...info,
      [e.target.name]: e.target.value,
    });
  };

  const toggleAgree = () => {
    setAgree(!agree);
  };

  const validate = () => {
    if (info.name.trim() === '') {
      alert(t('이름을 입력해주세요.'));
      nameRef.current.focus();
      return false;
    } else if (info.email.trim() === '') {
      alert(t('이메일을 입력해주세요.'));
      emailRef.current.focus();
      return false;
    } else if (info.message.trim() === '') {
      alert(t('내용을 입력해주세요.'));
      messageRef.current.focus();
      return false;
    } else if (!agree) {
      alert(t('개인정보 수집 및 이용에 동의해주세요.'));
      return false;
    }
    return true;
  };

  const doSubmit = () => {
    if (isProcessing) {
      return;
    }
    if (!validate()) {
      return;
    }

    setProcessing(true);
    // 메일 발송
    axios.post('https://flymail.apis.flyground.co.kr/send', {
      key: '355ca8ba-a166-11ed-a8fc-0242ac120002',
      to: ['admin@droneboda.com'],
      subject: 'Message from website',
      html: `
          <ul>
            <li>Name : ${info.name}<br /></li>
            <li>Email : ${info.email}<br /></li>
            <li>Company : ${info.company}<br /></li>
            <li>Position : ${info.position}<br /></li>
            <li>Message<br />${info.message}</li>
          </ul>
        `,
    });

    alert(t('접수가 완료되었습니다.'));
    setProcessing(false);
    window.location.replace('/');
  };

  return (
    <div className={cx('container')}>
      <form className={cx('modal')}>
        <div className={cx('header')}>
          <h1>Send us messages.</h1>
          <div className={cx('close')} onClick={close}>
            <IoCloseOutline size={40} />
          </div>
        </div>
        <ul className={cx('body')}>
          <li>
            <label htmlFor="name">{t('성명')}</label>
            <input
              ref={nameRef}
              name="name"
              type="text"
              value={info.name}
              placeholder={t('성명')}
              maxLength={16}
              disabled={isProcessing}
              onChange={handleChange}
            />
          </li>
          <li>
            <label htmlFor="email">{t('이메일')}</label>
            <input
              ref={emailRef}
              name="email"
              type="text"
              value={info.email}
              placeholder={t('이메일')}
              maxLength={120}
              disabled={isProcessing}
              onChange={handleChange}
            />
          </li>
          <li>
            <label htmlFor="company">{t('소속')}</label>
            <input
              name="company"
              type="text"
              value={info.company}
              placeholder={t('소속')}
              maxLength={36}
              disabled={isProcessing}
              onChange={handleChange}
            />
          </li>
          <li>
            <label htmlFor="position">{t('직책')}</label>
            <input
              name="position"
              type="text"
              value={info.position}
              placeholder={t('직책')}
              maxLength={36}
              disabled={isProcessing}
              onChange={handleChange}
            />
          </li>
          <li className={cx('fullWidth')}>
            <label htmlFor="message">{t('내용')}</label>
            <textarea
              ref={messageRef}
              name="message"
              value={info.message}
              placeholder={t('내용')}
              maxLength={1000}
              disabled={isProcessing}
              onChange={handleChange}
            ></textarea>
          </li>
        </ul>
        <div className={cx('agreeWrapper')} onClick={toggleAgree}>
          <div className={cx('checkbox')}>
            {agree && <IoCheckmarkOutline size={24} color="black" />}
          </div>
          <div className={cx('agreeText')}>
            {t('개인정보 수집 및 이용에 동의합니다.')}
          </div>
        </div>
        <div className={cx('button')} onClick={doSubmit}>
          {isProcessing ? t('보내는 중...') : t('보내기')}
        </div>
      </form>
    </div>
  );
};

export default Section;
