import React, { useRef, useEffect } from 'react';
import classNames from 'classnames/bind';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useTranslation } from 'react-i18next';

import styles from './S02.module.scss';

const cx = classNames.bind(styles);
gsap.registerPlugin(ScrollTrigger);

const Section = () => {
  const { t } = useTranslation();

  const wrapperRef = useRef();
  const marquee1Ref = useRef();
  const marquee2Ref = useRef();

  useEffect(() => {
    // Marquee by scroll
    gsap.to(marquee1Ref.current, {
      x: '120rem',
      scrollTrigger: {
        trigger: wrapperRef.current,
        start: 'top bottom',
        end: 'top top',
        scrub: true,
      },
    });
    gsap.to(marquee2Ref.current, {
      x: '-120rem',
      scrollTrigger: {
        trigger: wrapperRef.current,
        start: 'top bottom',
        end: 'top top',
        scrub: true,
      },
    });
  }, []);

  return (
    <div className={cx('container')}>
      <div className={cx('wrapper')}>
        <h1>Our Story</h1>
      </div>
      <div ref={wrapperRef} className={cx('marqueeWrapper')}>
        <p ref={marquee1Ref} className={cx(['marquee', 'm01'])}>
          {t('새로운 생태계를 위해,')}
        </p>
        <p ref={marquee2Ref} className={cx(['marquee', 'm02'])}>
          {t('드론청년들이 모였습니다.')}
        </p>
      </div>
    </div>
  );
};

export default Section;
