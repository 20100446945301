import React, { useState, useEffect } from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Loader } from '@googlemaps/js-api-loader';

import styles from './S06.module.scss';
import T01 from './T01';

const cx = classNames.bind(styles);

const Section = () => {
  const { t } = useTranslation();
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    new Loader({
      apiKey: 'AIzaSyAD87jHfqAkux6UGi_MV8lKJskejLdBLqk',
      version: 'weekly',
      libraries: ['places'],
    })
      .load()
      .then((google) => {
        const center = {
          lat: 35.15457775,
          lng: 126.84947009999999,
        };
        const map = new google.maps.Map(document.getElementById('map'), {
          center,
          zoom: 16,
        });

        new google.maps.Marker({
          position: center,
          map,
        });
      });
  });

  return (
    <div id="contacts" className={cx('container')}>
      <div className={cx('wrapper')}>
        <div className={cx('columns')}>
          <div className={cx('left')}>
            <h1>Contacts</h1>
            <p>Be the first to catch up</p>
          </div>
          <ul>
            <li>
              <div className={cx('title')}>Tel</div>
              <div className={cx('content')}>02 6952 0825</div>
            </li>
            <li>
              <div className={cx('title')}>Address</div>
              <div className={cx('content')}>
                {t('광주 서구 상무중앙로 78번길 5-6')} {t('영효빌딩 9층')}
              </div>
            </li>
            <li>
              <div className={cx('title')}>Email</div>
              <div className={cx('content')}>admin@droneboda.com</div>
            </li>
          </ul>
        </div>
        <div id="map" className={cx('map')} />
        <div className={cx('button')} onClick={() => setShowForm(true)}>
          Any Questions?
        </div>
      </div>
      {showForm && <T01 close={() => setShowForm(false)} />}
    </div>
  );
};

export default Section;
