import React, { useEffect, useRef } from 'react';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useTranslation } from 'react-i18next';

import styles from './S05.module.scss';

const cx = classNames.bind(styles);

const Section = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const stickyRef = useRef();

  useEffect(() => {
    ScrollTrigger.create({
      start: stickyRef.current.offsetTop,
      onEnter: () => stickyRef.current?.classList.add(cx('active')),
      onEnterBack: () => stickyRef.current?.classList.add(cx('active')),
      onLeave: () => stickyRef.current?.classList.remove(cx('active')),
      onLeaveBack: () => stickyRef.current?.classList.remove(cx('active')),
    });
  }, []);

  const moveToNalida = () => {
    navigate('/nalida');
  };

  return (
    <div className={cx('container')}>
      <div className={cx('wrapper')}>
        <h1>{t('국내 유일 드론 매칭이 이루어지는 곳')}</h1>
        <h1>{t('전문가. 조종사. 고객. 들이 함께 하는 곳')}</h1>
        <div className={cx('columns')}>
          <div className={cx('left')}>
            <div ref={stickyRef} className={cx('sticky')}>
              <img
                src={require('../../assets/images/home/S05/nalida.png')}
                alt="NALIDA"
                className={cx('bi')}
              />
              <div className={cx('paragraph')}>
                <p>
                  {t('드론 조종사들은')}
                  <br />
                  {t('드론을 통해 쉽게 배우고 수익을 창출할 수 있습니다.')}
                </p>
                <br />
                <p>
                  {t('데이터 사용자들은')}
                  <br />
                  {t('7일 이내 전국 어디서든 결과물을 받을 수 있습니다.')}
                </p>
              </div>
              <div className={cx('button')} onClick={moveToNalida}>
                {t('시작하기')}
              </div>
            </div>
          </div>
          <ul>
            <li>
              <img
                src={require('../../assets/images/home/S05/illust-01.png')}
                alt={`${t('투명한 드론정보 및')} ${t(
                  '무료홍보를 제공합니다.'
                )}`}
              />
              <h2>
                {t('투명한 드론정보 및')}
                <br />
                {t('무료홍보를 제공합니다.')}
              </h2>
              <p>{t('가격, 위치, 리뷰, 포트폴리오, 평점')}</p>
            </li>
            <li>
              <img
                src={require('../../assets/images/home/S05/illust-02.png')}
                alt={`${t('검증된 전문가로부터')} ${t(
                  '드론 실무 교육을 받을 수 있습니다.'
                )}`}
              />
              <h2>
                {t('검증된 전문가로부터')}
                <br />
                {t('드론 실무 교육을 받을 수 있습니다.')}
              </h2>
              <p>{t('실무경험, 영상, 사진 등')}</p>
            </li>
            <li>
              <img
                src={require('../../assets/images/home/S05/illust-03.png')}
                alt={`${t('어디서든 데이터를')} ${t('취득할 수 있습니다.')}`}
              />
              <h2>
                {t('어디서든 데이터를')}
                <br />
                {t('취득할 수 있습니다.')}
              </h2>
              <p>{t('A 부터 Z 까지 예약제 서비스')}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Section;
