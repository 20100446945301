import React from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import styles from './S03.module.scss';

const cx = classNames.bind(styles);

const Section = () => {
  const { t } = useTranslation();

  return (
    <div className={cx('container')}>
      <div className={cx('top')}>
        <p>
          {t('드론 시장은 빠르게 성장하고 있습니다.')}
          <br />
          {t('아직 많은 문제들')}
          <sup>*</sup>
          {t('이 존재합니다.')}
        </p>
        <ul>
          <li>
            <sup>*</sup>
            <strong>{t('문제점 1.')}</strong>&nbsp;
            {t('드론 전문가와 고객간 소통 채널의 부재')}
          </li>
          <li>
            <strong>{t('문제점 2.')}</strong>&nbsp;
            {t('기업, 개인, 전문가들의 검증 불가')}
          </li>
          <li>
            <strong>{t('문제점 3.')}</strong>&nbsp;
            {t('데이터의 신뢰도 및 교육의 부재')}
          </li>
        </ul>
      </div>
      <div className={cx('stream')}>
        {[0, 1].map((index) => (
          <ul key={index}>
            <li className={cx(['balloon', 't01', 'b01'])}>
              {t('드론 데이터가 너무 복잡해요')}
              <div className={cx('arrow')} />
            </li>
            <li className={cx(['balloon', 't01', 'b02'])}>
              {t('드론 전문가들은 어떻게 찾을 수 있나요?')}
              <div className={cx('arrow')} />
            </li>
            <li className={cx(['balloon', 't02', 'b03'])}>
              {t('정말 드론을 사용하면 좋나요?')}
              <div className={cx('arrow')} />
            </li>
            <li className={cx(['balloon', 't01', 'b04'])}>
              {t('드론 공부는 어디서 할 수 있을까요?')}
              <div className={cx('arrow')} />
            </li>
            <li className={cx(['balloon', 't02', 'b05'])}>
              {t('드론은 정말 안전한 것인가?')}
              <div className={cx('arrow')} />
            </li>
            <li className={cx(['ball', 'b06'])}>{t('산업융합의 부재')}</li>
            <li className={cx(['ball', 'b07'])}>{t('복잡한 데이터')}</li>
          </ul>
        ))}
      </div>
      <div className={cx('bottom')}>
        <p>
          {t('보다는 드론 현장과')}&nbsp;
          <br />
          {t('시장의 목소리를 바탕으로 모인')}&nbsp;
          <br />
          {t('문제점을 ')}
          <span className={cx('accent')}>{t('기회')}</span>
          {t('로 바꾸고자하는 스타트업입니다.')}
        </p>
      </div>
    </div>
  );
};

export default Section;
