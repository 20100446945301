import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Home from './pages/home';
import Nalida from './pages/nalida';
import Header from './components/Header';
import Footer from './components/Footer';
import ScrollTop from './components/ScrollTop';
import './i18n';

const App = () => {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/nalida" exact element={<Nalida />} />
      </Routes>
      <Footer />
      <ScrollTop />
    </BrowserRouter>
  );
};

export default App;
