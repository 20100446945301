import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';
import { IoMenuOutline, IoCloseOutline } from 'react-icons/io5';

import styles from './Header.module.scss';

const cx = classNames.bind(styles);

const Header = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [scrolled, setScrolled] = useState(false);
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScrolled(window.pageYOffset > 0);
    });
  }, []);

  useEffect(() => {
    setOpen(false);
  }, [location]);

  const refreshToHome = () => {
    window.location.href = '/';
  };

  const toggle = () => {
    setOpen(!isOpen);
  };

  const moveTo = (path) => {
    if (window.location.pathname === path) {
      setOpen(false);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } else {
      navigate(path);
    }
  };

  return (
    <div className={cx(['container', { scrolled }])}>
      <div className={cx('wrapper')}>
        <div className={cx('logo')} onClick={refreshToHome}>
          <img
            src={require('../assets/images/logo.png')}
            alt="BODA"
            draggable={false}
          />
        </div>
        <div className={cx(['menus', { open: isOpen }])}>
          <div className={cx('menu')} onClick={() => moveTo('/')}>
            HOME
          </div>
          <div
            to="/nalida"
            className={cx('menu')}
            onClick={() => moveTo('/nalida')}
          >
            NALIDA
          </div>
          <HashLink to="/#contacts" smooth={true} className={cx('menu')}>
            CONTACT
          </HashLink>
        </div>
        <div className={cx(['langs', { open: isOpen }])}>
          <div
            className={cx(['lang', { active: i18n.language === 'ko' }])}
            onClick={() => i18n.changeLanguage('ko')}
          >
            KOR
          </div>
          <div className={cx('bar')} />
          <div
            className={cx(['lang', { active: i18n.language === 'en' }])}
            onClick={() => i18n.changeLanguage('en')}
          >
            ENG
          </div>
        </div>
        <div className={cx('toggleButton')} onClick={toggle}>
          {isOpen && <IoCloseOutline size={40} color="white" />}
          {!isOpen && <IoMenuOutline size={40} color="white" />}
        </div>
      </div>
    </div>
  );
};

export default Header;
